export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1"},{"hid":"description","name":"description","content":"OnArt - Virtual Galleries for artists from artists"},{"hid":"og:image","property":"og:image","content":"/staticplattform/img/thumbnail.jpg"},{"hid":"google","property":"google","content":"notranslate"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[{"innerHTML":"\n\t\t\t\t\t!function(f,b,e,v,n,t,s)\n\t\t\t\t\t{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n\t\t\t\t\tn.callMethod.apply(n,arguments):n.queue.push(arguments)};\n\t\t\t\t\tif(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\n\t\t\t\t\tn.queue=[];t=b.createElement(e);t.async=!0;\n\t\t\t\t\tt.src=v;s=b.getElementsByTagName(e)[0];\n\t\t\t\t\ts.parentNode.insertBefore(t,s)}(window, document,'script',\n\t\t\t\t\t'https://connect.facebook.net/en_US/fbevents.js');\n\t\t\t\t\tfbq('init', '859750742241007');\n\t\t\t\t\tfbq('track', 'PageView');\n\t\t\t\t  ","type":"text/javascript"}],"noscript":[],"title":"OnArt - Virtual Galleries for artists from artists","htmlAttrs":{"lang":"en","translate":"no"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null